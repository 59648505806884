<template>
    <div style="width: 100%">
        <div style="width: 100%;display: block" v-for="(item,idex) in list" :key="item.id">
            <div class="agenttitle"
                 v-if="item.parentId == managerConst.Parnter.PATNER_GENERALAGENT_NOID">총판정보
            </div>
            <div class="agentline">
                <div style="width: 4%;text-align: center;color: #c6c6c6">
                    [{{item.id}}]
                </div>
                <div style="width: 3%">
                    <span v-if="item.status == managerConst.ENABLE">[정상]</span>
                    <span style="color: red" v-if="item.status == managerConst.DISABLE">[블럭]</span>

                </div>

                <div style="width: 6%">
                    <span>회원[{{item.userCount|comma}}]</span>
                </div>

                <div style="width: 5%">
                    블럭<span style="color: red">[{{item.userCountBlock|comma}}]</span>
                </div>
                <div style="width: 11%">
                    보유<i class="fa fa-won"></i><span style="color: #afafaf">: {{item.cash|comma}} 원</span>
                </div>
                <div style="width:56%">
                    <span v-if="item.parentId != managerConst.Parnter.PATNER_GENERALAGENT_NOID">|</span>
                    <span v-for="index in item.level"
                          v-if="item.parentId != managerConst.Parnter.PATNER_GENERALAGENT_NOID">---</span>
                    <span style="color: red;font-size: 11px;padding: 0 5px">lv[{{item.level}}]</span>
                    <span class="badge badge-warning" style="color: #143771">{{item.nickname}}</span> <span
                        style="color: #9b9999">({{item.account}})</span>
                    <i class="fa fa-hand-o-right" style="padding: 0 5px"></i>


                    <span style="color: skyblue;font-size: 11px">손이익(<span style="color: #fff9ff">{{item.rechargeExchangeProfit}}</span>)%</span>,
                    <span style="color: orange;font-size: 11px">미니게임롤링(<span style="color: #fff9ff">{{item.leisureBetRollingProfit}}</span>)%</span>,
                    <span style="color: skyblue;font-size: 11px">카지노롤링(<span style="color: #fff9ff">{{item.casinoBetRollingProfit}}</span>)%</span>,
                    <span style="color: orange;font-size: 11px">슬롯롤링(<span style="color: #fff9ff">{{item.slotBetRollingProfit}}</span>)%</span>,
                    <span style="color: skyblue;font-size: 11px">스포츠롤링(<span style="color: #fff9ff">{{item.sportsBetRollingProfit}}</span>)%</span>


                </div>


                <div style="width: 25%;text-align: right">
                    <el-button type="danger" size="mini" @click="addPartner(item.id,managerConst.MODE_EDIT)"><i
                            class="fa fa-pencil-square"></i> 총판수정
                    </el-button>
                    <el-button type="primary" size="mini" @click="editPartner(item.id,managerConst.MODE_SAVE)"><i
                            class="fa fa-plus-square"></i> 하위총판추가
                    </el-button>
                </div>

            </div>
            <div v-if="isshowprofit" style="background-color: #9e9e9e;padding: 5px 0">
                <span style="color:#d8f84a;">본인&회원입금:{{item.currentAgentRecharegeAmountTotal|comma}}</span> |
                <span style="color:#d8f84a;">본인회원출금:-{{item.currentAgentExchangeAmountTotal|comma}}</span> |
                <span style="color:#0510e7;font-weight: bold">본인회원손이익:{{item.ownUserProfit|comma}}</span>
            </div>
            <div v-if="isshowprofit" style="background-color: #c1c1c1;padding: 5px 0">
                <span style="color:#607600;">하위&회원입금:{{item.subAgentRecharegeAmountTotal|comma}}</span> |
                <span style="color:#607600;">하위회원출금:-{{item.subAgentExchangeAmountTotal|comma}}</span> |
                <span style="color:#4c55f6;font-weight: bold">하위회원손이익:{{item.subUserProfit|comma}}</span>
            </div>
            <div v-if="isshowprofit" style="background-color: #c1c1c1;padding: 5px 0">
                <span style="color: #5477f5"
                      v-if="item.givebackdate">최근정산일:{{item.givebackdate|datef('MM-DD HH:mm')}} | </span>
                전체손이익<i class="fa fa-won"></i><span style="color: #5e0b0b">:{{(item.ownUserProfit + item.subUserProfit)|comma}}</span>
                <el-button type="warning" size="mini" style="margin-left: 10px"
                           @click="givebackprofit(item.id,item.nickname,item.ownUserProfit + item.subUserProfit)"><i
                        class="fa fa-pencil-square"></i> 정산하기
                </el-button>
            </div>
            <agent-recursion-comp v-if="item.subAgentList" :list="item.subAgentList"
                                  :isshowprofit="isshowprofit"></agent-recursion-comp>
        </div>
    </div>

</template>

<script>
    import Vue from "vue";
    import AgentEditToast from "./UserPanel/AgentEditToast";
    import {agentMixin} from "../../common/agent/agentMixin";
    import managerConst from "../../common/administrator/managerConst";
    import {manager} from "../../common/administrator/managerMixin";
    import {getAgentGiveBackApplyInfo, givebackprofit} from "../../network/manager/agentRequest";
    import {Loading} from "element-ui";
    import {updateSportsBetInfo} from "../../network/manager/sportsBetRequest";

    Vue.prototype.$agentEditToast = AgentEditToast

    export default {
        name: "AgentRecursionComp",
        mixins: [manager],
        props: {
            list: {
                type: Array,
                default() {
                    return []
                }
            },
            isshowprofit: {
                type: Boolean,
                default() {
                    return false
                }
            }

        },
        data() {
            return {
                agent: {},
                editParnterId: 0,
            }
        },
        methods: {
            givebackprofit(id, nickname, amount) {
                let title = '총판 [' + nickname + '] 에게 ' + amount + '원 정산을 하시겠습니까?'
                this.$confirm(title, '업데이트', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    givebackprofit(id).then(res => {
                        console.log(res)
                        if (res.data.success) {
                            this.$bus.$emit('agentgivebackend')
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                        loadingInstance.close();
                    })
                });

            },
            editPartner(id, mode) {
                this.$agentEditToast({'agentId': id, 'mode': mode})
            },
            addPartner(parentId, mode) {
                this.$agentEditToast({'agentId': parentId, 'mode': mode})
            },
        }
    }
</script>

<style scoped>
    .agentline {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        height: 32px;
        width: 100%;
        background-color: #333333;
        color: #fff9ff;
        border-bottom: 1px solid #505050
    }

    .agenttitle {
        margin-top: 30px;
        background: linear-gradient(180deg, #5475f8, #b0bcf5);
        line-height: 30px;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        color: #fff9ff;
        padding-left: 10px;
        font-weight: bold;
        border-radius: 10px 10px 0 0;
    }
</style>